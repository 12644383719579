import React from 'react';
import { Absence, User } from '../../Types';
import { getHolidayBalanceColor } from '../../helpers/holidays';
import { formatDate, getBackgroundColor } from '../../helpers/absenceRequests';
import Comment from '../../assets/Icons/LeadIcons/Comment.svg';

interface EmployeeListProps {
  employees: User[];
  absences: Absence[];
  sickDaysMap: {[key: string]: number};
  holidayBalanceMap: {[key: string]: number};
  vacationDaysAsOfTodayMap: {[key: string]: number};
  vacationDaysPlannedMap: {[key: string]: number};
  sortByHolidayAccrual: boolean;
  sortBySickDays: boolean;
  sortByVacationDaysAsOfToday: boolean;
  sortByVacationDaysPlanned: boolean;
}

const EmployeeList = (
  {
    employees,
    absences,
    sickDaysMap,
    holidayBalanceMap,
    vacationDaysAsOfTodayMap,
    vacationDaysPlannedMap,
    sortByHolidayAccrual,
    sortBySickDays,
    sortByVacationDaysAsOfToday,
    sortByVacationDaysPlanned
  }: EmployeeListProps
) => {
  const getEmployeesSorted = (employees: User[]) => {
    if (sortByHolidayAccrual) {
      return employees.sort((a, b) => {
        return holidayBalanceMap[b.userId] - holidayBalanceMap[a.userId];
      });
    }
    if (sortBySickDays) {
      return employees.sort((a, b) => {
        return sickDaysMap[b.userId] - sickDaysMap[a.userId];
      });
    }
    if (sortByVacationDaysAsOfToday) {
      return employees.sort((a, b) => {
        return vacationDaysAsOfTodayMap[b.userId] - vacationDaysAsOfTodayMap[a.userId];
      });
    }
    if (sortByVacationDaysPlanned) {
      return employees.sort((a, b) => {
        return vacationDaysPlannedMap[b.userId] - vacationDaysPlannedMap[a.userId];
      });
    }
    return employees;
  };
  return <React.Fragment>
    {getEmployeesSorted(employees).map((employee, index) => {
      const currentDate = new Date();
      currentDate.setUTCHours(0, 0, 0, 0);
      const memberAbsences = absences.filter(absence => absence.userId === employee.userId && absence.status === 'Approved');

      const sortedAbsences = memberAbsences.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

      const currentAndFutureAbsences: (Absence | null)[] = sortedAbsences.filter(absence => {
        const startDate = new Date(absence.startDate);
        const endDate = new Date(absence.endDate);
        startDate.setUTCHours(0, 0, 0, 0);
        endDate.setUTCHours(0, 0, 0, 0);

        return (startDate >= currentDate || (startDate < currentDate && endDate >= currentDate));
      }) || null;

      if (currentAndFutureAbsences.length === 0) {
        currentAndFutureAbsences.push(null);
      }

      return currentAndFutureAbsences.map((currentOrNextAbsence, i, absences) => {

        let startDate, endDate;
        if (currentOrNextAbsence !== null) {
          startDate = new Date(currentOrNextAbsence.startDate);
          endDate = new Date(currentOrNextAbsence.endDate);
          startDate.setUTCHours(0, 0, 0, 0);
          endDate.setUTCHours(0, 0, 0, 0);
        }

        let status = 'Nothing Planned';

        if (currentOrNextAbsence) {
          if (startDate && endDate && (startDate <= currentDate && endDate >= currentDate)) {
            if (currentOrNextAbsence.reason === 'Sick Child') {
              status = `${currentOrNextAbsence.reason}`;
            } else {
              status = `On ${currentOrNextAbsence.reason}`;
            }
          } else if (startDate && startDate > currentDate) {
            status = 'Planned';
          }
        }

        let formattedStartDate, formattedEndDate;
        if (currentOrNextAbsence) {
          formattedStartDate = formatDate(new Date(currentOrNextAbsence.startDate));
          formattedEndDate = formatDate(new Date(currentOrNextAbsence.endDate));
        }

        return (
          <div className='columns-container' key={`${i}${index}`}>
            {i !== 0 && absences[i-1]?.userId === employee.userId ?
            <React.Fragment>
              <div className='column'>
                <div className='user-info'>
                  <span className='user-name'></span>
                  <span className='user-title'></span>
                </div>
              </div>
              <div className='column'>
                <span className='holiday-accrual'></span>
              </div>
              <div className='column'>
                <span className='holiday-accrual'></span>
              </div>
              <div className='column'>
                <span className='holiday-accrual'></span>
              </div>
              <div className='column'>
                <span className='holiday-accrual'></span>
              </div>
            </React.Fragment>
            :
            <React.Fragment>
              <div className='column'>
                <div className='user-info'>
                  <span className='user-name'>{employee.name}</span>
                  <span className='user-title'>{employee.title}</span>
                </div>
              </div>
              <div className='column'>
                <span className='holiday-accrual'>{vacationDaysAsOfTodayMap[employee.userId] === undefined ? '' : vacationDaysAsOfTodayMap[employee.userId]}</span>
              </div>
              <div className='column'>
                <span className='holiday-accrual'>{vacationDaysPlannedMap[employee.userId] === undefined ? '' : vacationDaysPlannedMap[employee.userId]}</span>
              </div>
              <div className='column'>
                <span className='holiday-accrual' style={{color: getHolidayBalanceColor(holidayBalanceMap[employee.userId])}}>{holidayBalanceMap[employee.userId]}</span>
              </div>
              <div className='column'>
                <span className='holiday-accrual'>{sickDaysMap[employee.userId] === undefined ? '' : sickDaysMap[employee.userId]}</span>
              </div>
            </React.Fragment>}
            <div className='column'>
              {currentOrNextAbsence && (
                <div className='absence-container'
                  title={`${currentOrNextAbsence.reason}${currentOrNextAbsence.information === '' ? '' : ' - '}${currentOrNextAbsence.information}`}
                  style={{
                    background: getBackgroundColor(currentOrNextAbsence.reason).backgroundColor,
                    cursor: 'pointer',
                  }}
                  onClick={() => alert(`${currentOrNextAbsence.reason}${currentOrNextAbsence.information === '' ? '' : ' - '}${currentOrNextAbsence.information}`)}
                >
                  {getBackgroundColor(currentOrNextAbsence.reason).icon && (
                    <img
                      src={getBackgroundColor(currentOrNextAbsence.reason).icon}
                      alt={currentOrNextAbsence.reason}
                      className='absence-icon'
                    />
                  )}
                  <p className='absence-date'>
                    {formattedStartDate === formattedEndDate ? formattedStartDate :
                    `${formattedStartDate} - ${formattedEndDate}`}
                    <img
                      src={Comment}
                      alt='Comment'
                      className='comment-icon'
                      title={`${currentOrNextAbsence.reason}${currentOrNextAbsence.information === '' ? '' : ' - '}${currentOrNextAbsence.information}` || ''}
                    />
                  </p>
                </div>
              )}
              {!currentOrNextAbsence && (
                <div className='absence-container' style={{ width: '100%', height: '100%' }}></div>
              )}
            </div>
            <div className='column' style={{ width: '338px' }}>
              <div className='status-text' style={{
                color: status !== 'Nothing Planned' ? getBackgroundColor(currentOrNextAbsence?.reason || '').backgroundColor : '#929292',
              }}>
                {status}
              </div>
            </div>
          </div>
        );
      });
    })}
  </React.Fragment>;
};

export default EmployeeList;